import React from "react";
import Text from "./Text";
import { Mobile } from "../../Bitter";
import { Colors } from "../../Helpers";

const Component = (props) => {
  return (
    <Mobile
      render={(mobile) => {
        const { rule = "Body" } = props;
        return rule === "Title" ? (
          <Text
            display="block"
            size={24}
            family="Lufga"
            weight={600}
            {...props}
          />
        ) : rule === "Button" ? (
          <Text size={14} family="Lufga" weight={600} {...props} />
        ) : rule === "Body" ? (
          <Text size={14} family="Lufga" weight={400} color="#505050" {...props} />
        ) : rule === "Small" ? (
          <Text size={12} family="Lufga" weight={500} color="#909090" {...props} />
        ) : rule === "Tiny" ? (
          <Text size={9} family="Lufga" weight={500} {...props} />
        ) : rule === "Label" ? (
          <Text
            size={12}
            family="Lufga"
            weight={600}
            color="#555555"
            display="block"
            bottom={4}
            {...props}
          />
        ) : (
          <Text {...props} />
        );
      }}
    />
  );
};

export default Component;
