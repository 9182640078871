import React, { useState, useEffect } from "react";
import { Box, Rule, Icon, Mobile, Button, Input } from "../../Bitter";
import { Colors, Functions, Resources } from "../../Helpers";

const Component = ({
  icon,
  title,
  description,
  buttons = [{ theme: "primary", text: "Okay" }],
  standalone = true,
  active,
  done,
}) => {
  const [value, set] = useState(active);
  const [loading, _loading] = useState(false);
  const [license, _license] = useState(null);
  useEffect(() => {
    set(active);
  }, [active]);

  useEffect(() => {
    const local = localStorage.getItem("license");
    if(local){
      done(local);
    }
  }, []);

  return (
    <>
      <Box
        width={256}
        color={Colors.white}
        mode="padding"
        all={10}
        radius={8}
        shadow="small"
        layer={11}
        css={standalone ? "--popup" : undefined}
      >
        {title ? (
          <Box display="flex" align="center" bottom={5}>
            {(() => {
              switch (icon) {
                case "error":
                  return (
                    <Box
                      width={18}
                      height={18}
                      display="inline-flex"
                      color="#F7F7F7"
                      radius={999}
                      right={5}
                      align="center"
                      justify="center"
                      shadow="small"
                    >
                      <Icon color="#CCCCCC" size={12} icon="x" />
                    </Box>
                  );
                  break;
                case "success":
                  return (
                    <Box
                      width={18}
                      height={18}
                      display="inline-flex"
                      color="#F7F7F7"
                      radius={999}
                      right={5}
                      align="center"
                      justify="center"
                      shadow="small"
                    >
                      <Icon color="#CCCCCC" size={12} icon="check" />
                    </Box>
                  );
                  break;
                default:
                  return null;
              }
            })()}
            <Rule display="block" size={14} weight={600} color="#505050">
              {title}
            </Rule>
          </Box>
        ) : undefined}
        <Rule size={12} weight={500} color="#909090" bottom={5} display="block">
          {description}
        </Rule>
        <Box bottom={20} top={15}>
          <Input
            placeholder="0000-0000-0000-0000"
            label="License Key"
            value={license}
            change={(edit) => _license(edit)}
          />
        </Box>
        <Box left={5} right={5} flex={1}>
          <Button
            loading={loading}
            width="100%"
            silent={true}
            style={{ width: "100%" }}
            theme="primary"
            press={async () => {
              _loading(true);
              await done(license);
              _loading(false);
            }}
          >
            Enter
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Component;
