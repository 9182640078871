import React from "react";
import { connect } from "react-redux";

import { Box, Rule, Text } from "../Bitter";
import { Functions, Colors, Resources } from "../Helpers";
import { API } from "../Modules";

import Lodash from "lodash";

import {
  Screen,
  SearchInput,
  FilterPopup,
  IconButton,
  Glass,
  Popup,
  Button,
  Form,
  Dropdown
} from "../Bitter";

import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";

class Page extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      component: {},
      trash: false,
      update: false,
      refund: false,
      _refund: false,
      refunded: false,
    };
  }
  _refund = async () => {
    const { component } = this.state;
    this.setState({ _refund: false });
    const response = await API.post("/dashboard/refund", {
      donation: component._id,
    });
    if (response) {
      this.setState({ refunded: true });
      this._reload();
    }
  };

  async componentDidMount() {
    this._reload();
  }

  _reload = async () => {
    const { entity } = this.props.match.params;
    this.setState({ loading: true });
    const entities = await API.post("/dashboard/donations", {
      filter: { _id: entity },
    });
    if (entities) {
      this.setState({
        component: entities.entities[0],
        loading: false,
        trash: false,
      });
    }
  };

  _delete = async (trash) => {
    const response = await API.post("/dashboard/donations", { delete: trash });
    if (response) {
      window.location = "/donations";
    }
  };

  _update = async () => {
    const { component } = this.state;
    const { entity } = this.props.match.params;
    const response = await API.post("/dashboard/donations", {
      entity,
      update: component,
    });
    if (response) {
      this.setState({ update: true });
      this._reload();
    }
  };

  render() {
    const {
      loading,
      trash,
      component,
      update,
      refund,
      _refund,
      refunded,
    } = this.state;
    const { entity } = this.props.match.params;

    return (
      <Box
        color="#FFFFFF"
        width="100%"
        height="100%"
        display="flex"
        align="center"
        justify="center"
        direction="column"
      >
        <Screen
          screen="donations"
          crumbs={["Home", "Donations"]}
          scroll={true}
          sticky={
            <Box display="flex" align="center" justify="space-between">
              <Rule rule="Title">{entity}</Rule>
              <Box display="flex" align="center">
                <Button theme="primary" press={this._update}>
                  Update
                </Button>
                <Button
                  left={10}
                  theme="tetriary"
                  press={() => this.setState({ trash: entity })}
                >
                  Delete
                </Button>
                <Dropdown
                  buttons={[
                    {
                      icon: "dollar-sign",
                      text: "Refund",
                      press: () => this.setState({ refund: true }),
                    },
                  ]}
                >
                  <IconButton size="medium" icon="more-vertical" left={10} />
                </Dropdown>
              </Box>
            </Box>
          }
        >
          {!loading ? (
            <Form
              change={(change) => {
                console.log(change);
                this.setState({ component: { ...component, ...change } });
              }}
              component={component}
              inputs={INPUTS}
            />
          ) : undefined}
        </Screen>
        <Glass active={trash}>
          <Popup
            active={trash}
            icon="error"
            title="Are you sure?"
            description={
              "You are going to be deleting the record with the id: " + trash
            }
            buttons={[
              {
                theme: "primary",
                press: () => this.setState({ trash: false }),
                text: "No",
              },
              {
                theme: "tetriary",
                press: () => this._delete(trash),
                text: "Yes",
              },
            ]}
          />
        </Glass>
        <Glass active={update}>
          <Popup
            active={update}
            icon="success"
            title="Hurray!"
            description={
              "Record with the id: " +
              entity +
              " has been successfully updated."
            }
            buttons={[
              {
                theme: "primary",
                press: () => this.setState({ update: false }),
                text: "Okay",
              },
            ]}
          />
        </Glass>
        <Glass active={refund}>
          <Popup
            active={refund}
            icon="warning"
            title="Are you sure?"
            description={
              "Before refunding, make sure you set the settings of this project correctly. The refunding process can not be converted back. Do you wish to proceed?"
            }
            buttons={[
              {
                theme: "primary",
                press: () => this.setState({ refund: false }),
                text: "No",
              },
              {
                theme: "tetriary",
                press: () => this.setState({ _refund: true, refund: false }),
                text: "Yes",
              },
            ]}
          />
        </Glass>
        <Glass active={_refund}>
          <Popup
            active={_refund}
            icon="warning"
            title="Last Warning"
            description={
              "The system will start refunding the donation. Do you wish to proceed?"
            }
            buttons={[
              {
                theme: "primary",
                press: () => this.setState({ refund: false }),
                text: "No",
              },
              {
                theme: "tetriary",
                press: this._refund,
                text: "Yes",
              },
            ]}
          />
        </Glass>
        <Glass active={refunded}>
          <Popup
            active={refunded}
            icon="success"
            title="Success"
            description="Donations is refunded!"
            buttons={[
              {
                theme: "primary",
                press: () => this.setState({ refunded: false }),
                text: "Okay",
              },
            ]}
          />
        </Glass>
      </Box>
    );
  }
}

const INPUTS = [
  {
    key: "refunded",
    label: "Refunded",
    type: "boolean",
  },
  {
    key: "project",
    label: "Project Identifier",
    type: "string",
  },
  {
    key: "user",
    label: "Donation Identifier",
    type: "string",
  },
  {
    key: "donationAmount",
    label: "Donation Amount",
    type: "number",
  },
  {
    key: "donationCurrency",
    label: "Donation Currency",
    type: "string",
  },
  {
    key: "donationOriginalAmount",
    label: "Donation Original Amount",
    type: "number",
  },
  {
    key: "stripeCheckoutIdentifier",
    label: "Stripe",
    type: "string",
  },
  {
    key: "donationCurrency",
    label: "Donation Currency",
    type: "string",
  },
  {
    key: "paymentIntent",
    label: "Payment Intent",
    type: "string",
  },
];

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Page);
