import React, { useState, useEffect } from "react";
import { Box, Rule, Icon, Mobile } from "../../Bitter";
import { Colors, Functions, Resources } from "../../Helpers";

const Component = ({
  value,
  change,
  bottom,
  left,
  right,
  top,
  style,
  placeholder = false,
  label = false,
}) => {
  return (
    <Box
      bottom={bottom}
      left={left}
      right={right}
      top={top}
      style={{ ...style }}
      position="relative"
    >
      {label ? (
        <Rule
          rule="Label"
          size={7}
          style={{
            position: "absolute",
            top: -3,
            bottom: "unset",
            right: "unset",
            backgroundColor: "white",
            left: 10,
          }}
        >
          {label}
        </Rule>
      ) : undefined}
      <Box
        radius={8}
        mode="padding"
        top={4}
        right={4}
        bottom={4}
        left={10}
        display="flex"
        height={26}
        align="center"
        color="#F7F7F7"
      >
        <Icon icon="search" right={4} size={12} color="#909090" />
        <input
          placeholder={placeholder}
          value={value}
          onChange={(event) => {
            if (change) {
              change(event.target.value);
            }
          }}
          style={{ flex: 1, fontSize: 10 }}
        />
      </Box>
    </Box>
  );
};

export default Component;
