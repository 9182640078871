import React from "react";
import { connect } from "react-redux";

import { Box, Rule, Text } from "../Bitter";
import { Functions, Colors, Resources } from "../Helpers";
import { API } from "../Modules";

import Lodash from "lodash";

import {
  Screen,
  SearchInput,
  FilterPopup,
  IconButton,
  Glass,
  Popup,
  Button,
  Form,
} from "../Bitter";

import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";

class Page extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      component: {},
      trash: false,
      update: false,
    };
  }

  async componentDidMount() {
    this._reload();
  }

  _reload = async () => {
    const { entity } = this.props.match.params;
    this.setState({ loading: true });
    const entities = await API.post("/dashboard/users", {
      filter: { _id: entity },
    });
    if (entities) {
      this.setState({
        component: entities.entities[0],
        loading: false,
        trash: false,
      });
    }
  };

  _delete = async (trash) => {
    const response = await API.post("/dashboard/users", { delete: trash });
    if (response) {
      window.location = "/users";
    }
  };

  _update = async () => {
    const { component } = this.state;
    const { entity } = this.props.match.params;
    const response = await API.post("/dashboard/users", {
      entity,
      update: component,
    });
    if (response) {
      this.setState({ update: true });
      this._reload();
    }
  };

  render() {
    const { loading, trash, component, update } = this.state;
    const { entity } = this.props.match.params;

    return (
      <Box
        color="#FFFFFF"
        width="100%"
        height="100%"
        display="flex"
        align="center"
        justify="center"
        direction="column"
      >
        <Screen
          screen="users"
          crumbs={["Home", "Users"]}
          scroll={true}
          sticky={
            <Box display="flex" align="center" justify="space-between">
              <Rule rule="Title">{entity}</Rule>
              <Box display="flex" align="center">
                <Button theme="primary" press={this._update}>
                  Update
                </Button>
                <Button
                  left={10}
                  theme="tetriary"
                  press={() => this.setState({ trash: entity })}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          }
        >
          {!loading ? (
            <Form
              change={(change) => {
                console.log(change);
                this.setState({ component: { ...component, ...change } });
              }}
              component={component}
              inputs={INPUTS}
            />
          ) : undefined}
        </Screen>
        <Glass active={trash}>
          <Popup
            active={trash}
            icon="error"
            title="Are you sure?"
            description={
              "You are going to be deleting the record with the id: " + trash
            }
            buttons={[
              {
                theme: "primary",
                press: () => this.setState({ trash: false }),
                text: "No",
              },
              {
                theme: "tetriary",
                press: () => this._delete(trash),
                text: "Yes",
              },
            ]}
          />
        </Glass>
        <Glass active={update}>
          <Popup
            active={update}
            icon="success"
            title="Hurray!"
            description={
              "Record with the id: " +
              entity +
              " has been successfully updated."
            }
            buttons={[
              {
                theme: "primary",
                press: () => this.setState({ update: false }),
                text: "Okay",
              },
            ]}
          />
        </Glass>
      </Box>
    );
  }
}

const INPUTS = [
  {
    key: "theatredor",
    label: "Theatredor Identifier",
    type: "string",
  },
  {
    key: "name",
    label: "Name",
    type: "string",
  },
  {
    key: "surname",
    label: "Surname",
    type: "string",
  },
  {
    key: "email",
    label: "Email",
    type: "string",
  },
  {
    key: "username",
    label: "Username",
    type: "string",
  },
  {
    key: "userType",
    label: "Type",
    type: "group",
    options: [
      ["customer", "Customer"],
      ["creator", "Creator"],
    ],
  },
  {
    key: "isVerified",
    label: "Verified",
    type: "boolean",
  },
  {
    key: "temporary",
    label: "Temporary User",
    type: "boolean",
  },
  {
    key: "premium",
    label: "Premium",
    type: "boolean",
  },
  {
    key: "creatorDetails",
    label: "Payout Details",
    type: "details",
  },
  {
    key: "metadata",
    label: "Creator Details",
    type: "details",
  },
  {
    key: "projects",
    label: "Projects",
    type: "entities",
    quick: "project",
  },
  {
    key: "stars",
    label: "Stars",
    type: "entities",
    quick: "project",
  },
  {
    key: "likes",
    label: "Likes",
    type: "entities",
    quick: "project",
  },
  {
    key: "dislikes",
    label: "Dislikes",
    type: "entities",
    quick: "project",
  },
  {
    key: "donations",
    label: "Donations",
    type: "entities",
    quick: "donation",
  },
];

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Page);
