import React, { useState, useEffect } from "react";
import {
  Box,
  Rule,
  Icon,
  Mobile,
  Button,
  Input,
  CheckboxInput,
  Entities,
  Details,
  GroupInput,
  BoxAvatar,
} from "../../Bitter";
import { Colors, Functions, Resources } from "../../Helpers";
import Lodash from "lodash";

import { OuterClick } from "react-outer-click";

const Component = ({ inputs = [], change, display = true, component = {} }) => {
  const [value, set] = useState(component);

  useEffect(() => {
    set(component);
  }, [component]);

  if (!display) {
    return null;
  }

  return (
    <>
      {inputs.map((input) => (
        <Box bottom={20}>
          {(() => {
            switch (input.type) {
              case "box":
                return (() => {
                  let parameters = input.render(component);

                  return <BoxAvatar {...parameters} />;
                })();
                break;
              case "string":
                return (
                  <Input
                    label={input.label}
                    value={value[input.key]}
                    change={(edit) => {
                      let temporary = Lodash.clone(value);
                      temporary[input.key] = edit;
                      set(temporary);

                      if (change) {
                        change(temporary);
                      }
                    }}
                  />
                );
                break;
              case "number":
                return (
                  <Input
                    label={input.label}
                    value={parseFloat(value[input.key], 10)}
                    change={(edit) => {
                      let temporary = Lodash.clone(value);
                      temporary[input.key] = parseFloat(edit, 10);
                      set(temporary);

                      if (change) {
                        change(temporary);
                      }
                    }}
                  />
                );
                break;
              case "group":
                return (
                  <GroupInput
                    label={input.label}
                    value={value[input.key]}
                    change={(edit) => {
                      let temporary = Lodash.clone(value);
                      temporary[input.key] = edit;
                      set(temporary);

                      if (change) {
                        change(temporary);
                      }
                    }}
                    options={input.options}
                  />
                );
                break;
              case "boolean":
                return (
                  <CheckboxInput
                    label={input.label}
                    active={value[input.key]}
                    change={(edit) => {
                      let temporary = Lodash.clone(value);
                      temporary[input.key] = edit;
                      set(temporary);

                      if (change) {
                        change(temporary);
                      }
                    }}
                  />
                );
                break;
              case "entities":
                return (
                  <Entities
                    title={input.label}
                    quick={input.quick}
                    entities={(() => {
                      let temporary = [];

                      value[input.key] = value[input.key] || [];

                      value[input.key].map((item) => {
                        temporary.push([item]);
                      });

                      return temporary;
                    })()}
                  />
                );
                break;
              case "details":
                return (
                  <Details
                    title={input.label}
                    expand={input.expand}
                    style={{width: "calc(100vw - 324px)"}}
                    details={(() => {
                      let temporary = [];

                      value[input.key] = value[input.key] || [];

                      if (Array.isArray(value[input.key])) {
                        value[input.key].map((item) => {
                          if (typeof item === "object") {
                            Object.entries(item).forEach(([key, value]) => {
                              temporary.push([
                                key.toUpperCase().replaceAll("_", ""),
                                value,
                              ]);
                            });
                          } else {
                            temporary.push([item]);
                          }
                        });
                      } else {
                        if (typeof value[input.key] === "object") {
                          Object.entries(value[input.key]).forEach(
                            ([key, value]) => {
                              temporary.push([
                                key.toUpperCase().replaceAll("_", ""),
                                value,
                              ]);
                            }
                          );
                        }
                      }

                      return temporary;
                    })()}
                  />
                );
                break;
              default:
                return null;
            }
          })()}
        </Box>
      ))}
    </>
  );
};

export default Component;
