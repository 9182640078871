import React from "react";
import { connect } from "react-redux";

import { Box } from "../Bitter";
import { Functions } from "../Helpers";

import { Screen } from "../Bitter";

class Page extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: false };
  }

  render() {
    const { loading } = this.state;
    return (
      <Box
        color="#FFFFFF"
        width="100%"
        height="100%"
        display="flex"
        align="center"
        justify="center"
        direction="column"
      >
        <Screen screen="home" crumbs={["Home"]} />
      </Box>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Page);
