import React, { useState, useEffect } from "react";
import { Box, Rule, Icon, Mobile } from "../../Bitter";
import { Colors, Functions, Resources } from "../../Helpers";

const Component = ({ folder = [] }) => {
  return (
    <>
      <Box display="flex" align="center">
        {folder.map((file, f) => (
          <>
            <Rule rule="Body" size={12}>
              {file}
            </Rule>
            {folder.length - 1 === f ? null : (
              <Rule rule="Body" size={12} color="#909090" left={10} right={10}>
                /
              </Rule>
            )}
          </>
        ))}
      </Box>
    </>
  );
};

export default Component;
