import React, { useState, useEffect } from "react";
import { Box, Rule, Icon, Mobile, Checkbox } from "../../Bitter";
import { Colors, Functions, Resources } from "../../Helpers";

const Component = ({
  label,
  active = false,
  style,
  display,
  change = () => {},
}) => {
  const [value, set] = useState(active);
  useEffect(() => {
    set(active);
  }, [active]);

  return (
    <>
      <Box
        display="flex"
        align="center"
        mode="padding"
        left={10}
        right={10}
        style={{ minWidth: 200, ...style }}
        height={26}
        radius={8}
        border="1px solid #DDDDDD"
        press={() => {
          let _value = !value;

          if (change) {
            change(_value);
          }

          set(_value);
        }}
      >
        <Checkbox change={(value) => set(value)} active={value} />
        <Rule size={10} left={10}>
          {label}
        </Rule>
      </Box>
    </>
  );
};

export default Component;
