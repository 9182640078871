import React, { useState, useEffect } from "react";
import {
  Box,
  Rule,
  Icon,
  Mobile,
  Sidebar,
  Breadcrumb,
  LogoutAvatar,
} from "../../Bitter";
import { Colors, Functions, Resources } from "../../Helpers";

const Component = ({
  screen,
  crumbs = [],
  sticky,
  children,
  scroll = false,
}) => {
  return (
    <>
      <Box
        color="#FFFFFF"
        width="100%"
        height="100%"
        display="flex"
        mode="padding"
        all={20}
      >
        <Box width={256}>
          <Sidebar
            height="auto"
            active={screen}
            menu={[
              {
                icon: "pie-chart",
                key: "analytics",
                label: "Analytics",
                to: "/users",
              },
              { icon: "smile", key: "users", label: "Users", to: "/users" },
              {
                icon: "box",
                key: "projects",
                label: "Projects",
                to: "/projects",
              },
              {
                icon: "dollar-sign",
                key: "donations",
                label: "Donations",
                to: "/donations",
              },
            ]}
          />
        </Box>
        <Box left={20} flex={1} display="flex" direction="column">
          <Box
            bottom={20}
            display="flex"
            align="center"
            justify="space-between"
          >
            <Breadcrumb folder={crumbs} />
            <LogoutAvatar username="Admin" />
          </Box>
          {sticky ? <Box bottom={20}>{sticky}</Box> : undefined}
          <Box
            flex={1}
            style={
              scroll ? { overflowY: "auto", marginBottom: -20 } : undefined
            }
          >
            {children}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Component;
