import React from "react";
import { connect } from "react-redux";

import { Box, Rule, Text } from "../Bitter";
import { Functions, Colors, Resources } from "../Helpers";
import { API } from "../Modules";

import Lodash from "lodash";

import {
  Screen,
  SearchInput,
  FilterPopup,
  IconButton,
  Glass,
  Popup,
  SmallGroupInput,
} from "../Bitter";

import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";

const DEFAULT_FILTER = {};
const DEFAULT_PAYOUT_UNFINISHED_FILTER = {};

class Page extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      search: "",
      _filter: false,
      filter: {},
      entities: [],
      trash: false,
      view: "all",
    };
  }

  async componentDidMount() {
    this._reload();
  }

  _reload = async (view = "all") => {
    this.setState({ loading: true });
    const entities = await API.post("/dashboard/projects", { view });
    if (entities) {
      this.setState({
        entities: entities.entities,
        loading: false,
        trash: false,
        view,
      });
    }
  };

  _delete = async (trash) => {
    const response = await API.post("/dashboard/projects", { delete: trash });
    if (response) {
      this._reload();
    }
  };

  _view = async (view) => {
    this.setState({ view });
    this._reload(view);
  };

  render() {
    const {
      loading,
      search,
      filter,
      _filter,
      entities,
      trash,
      view,
    } = this.state;

    const rows =
      search.length > 0
        ? Lodash.filter(entities, (entity) =>
            JSON.stringify(entity).includes(search)
          )
        : Functions.filter(entities, { ...DEFAULT_FILTER, ...filter });

    return (
      <Box
        color="#FFFFFF"
        width="100%"
        height="100%"
        display="flex"
        align="center"
        justify="center"
        direction="column"
      >
        <Screen
          screen="projects"
          crumbs={["Home", "Projects"]}
          sticky={
            <Box display="flex" align="center" justify="space-between">
              <Rule rule="Title">Projects</Rule>
              <Box display="flex" align="center">
                <Box position="relative">
                  <Box display="flex" align="center">
                    <SmallGroupInput
                      right={10}
                      value={view}
                      change={(view) => this._view(view)}
                      options={[
                        ["all", "All"],
                        ["complete", "Completed"],
                        ["payout", "Payout"],
                      ]}
                      placeholder="View"
                      style={{ width: 100 }}
                    />
                    <IconButton
                      right={10}
                      icon="sliders"
                      press={() => this.setState({ _filter: !_filter })}
                    />
                  </Box>
                  <Box
                    position="absolute"
                    mode="position"
                    top="calc(100% + 10px)"
                    right="0"
                    left="unset"
                    bottom="unset"
                    layer={99}
                  >
                    <FilterPopup
                      display={_filter}
                      change={(filter) => this.setState({ filter })}
                      outer={() => this.setState({ _filter: false })}
                      filters={[
                        { type: "string", key: "name", label: "Name" },
                        {
                          type: "string",
                          key: "startDate",
                          label: "Start Date",
                        },
                        { type: "string", key: "endDate", label: "End Date" },
                        { type: "boolean", key: "public", label: "Public" },
                      ]}
                    />
                  </Box>
                </Box>

                <SearchInput
                  value={search}
                  change={(search) => this.setState({ search })}
                />
              </Box>
            </Box>
          }
        >
          {!loading ? (
            <DataGrid
              rows={rows}
              getRowId={(row) => row._id}
              columns={[
                ...Functions.columns({
                  id: "#",
                  name: "Name",
                  startDate: "Start Date",
                  endDate: "End Date",
                  projectMilestone: "Milestone",
                  collected: "Collected",
                  projectCurrency: "Currency",
                }),
                {
                  field: "actions",
                  type: "actions",
                  width: 48,
                  flex: 1,
                  getActions: (params) => [
                    <GridActionsCellItem
                      onClick={() =>
                        (window.location = "/projects/" + params.row._id)
                      }
                      icon={<EditIcon />}
                      label="Edit"
                    />,
                    <GridActionsCellItem
                      onClick={() => this.setState({ trash: params.row._id })}
                      icon={<DeleteIcon />}
                      label="Delete"
                    />,
                  ],
                },
              ]}
              initialState={{
                pinnedColumns: { left: ["id"], right: ["actions"] },
              }}
              checkboxSelection
              disableSelectionOnClick
            />
          ) : undefined}
        </Screen>
        <Glass active={trash}>
          <Popup
            active={trash}
            icon="Error"
            title="Are you sure?"
            description={
              "You are going to be deleting the record with the id: " + trash
            }
            buttons={[
              {
                theme: "primary",
                press: () => this.setState({ trash: false }),
                text: "No",
              },
              {
                theme: "tetriary",
                press: () => this._delete(trash),
                text: "Yes",
              },
            ]}
          />
        </Glass>
      </Box>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Page);
