import React, { useState, useEffect } from "react";
import { Box, Rule, Icon, Mobile } from "../../Bitter";
import { Colors, Functions, Resources } from "../../Helpers";

const Component = ({ icon, label, active, to }) => {
  return (
    <>
      <Box
        mode="padding"
        all={12}
        radius={8}
        shadow={active ? "small" : undefined}
        display="flex"
        align="center"
        color={active ? Colors.white : "transparent"}
        press={() => {
          if (to) {
            window.location = to;
          }
        }}
      >
        {icon ? (
          <Icon size={16} right={10} color="#909090" icon={icon} />
        ) : undefined}
        <Rule rule="Small" color="#1F1F1F">
          {label}
        </Rule>
      </Box>
    </>
  );
};

export default Component;
