import Lodash from "lodash";

class Functions {
  static async sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  static columns(keys) {
    let columns = [];
    Object.entries(keys).forEach(([key, value]) => {
      columns.push({
        field: key,
        headerName: value,
        width: 150,
        editable: false,
        disableReorder: false,
        flex: 1,
      });
    });

    return columns;
  }

  static filter(array, filter = {}) {
    console.log({ array });
    array = Lodash.filter(array, filter);
    console.log({ array });
    array.map((item, place) => {
      if (item._id) {
        array[place].id = item._id;
      }
    });
    return array;
  }
}

export default Functions;
