import React, { useState, useEffect } from "react";
import { Box, Rule, Icon, Mobile, Button } from "../../Bitter";
import { Colors, Functions, Resources } from "../../Helpers";

const Component = ({
  icon,
  title,
  description,
  buttons = [{ theme: "primary", text: "Okay" }],
  standalone = true,
  active,
}) => {
  const [value, set] = useState(active);
  useEffect(() => {
    set(active);
  }, [active]);

  return (
    <>
      <Box
        width={256}
        color={Colors.white}
        mode="padding"
        all={10}
        radius={8}
        shadow="small"
        layer={11}
        css={standalone ? "--popup" : undefined}
      >
        {title ? (
          <Box display="flex" align="center" bottom={5}>
            {(() => {
              switch (icon) {
                case "error":
                  return (
                    <Box
                      width={18}
                      height={18}
                      display="inline-flex"
                      color="#F7F7F7"
                      radius={999}
                      right={5}
                      align="center"
                      justify="center"
                      shadow="small"
                    >
                      <Icon color="#CCCCCC" size={12} icon="x" />
                    </Box>
                  );
                  break;
                case "success":
                  return (
                    <Box
                      width={18}
                      height={18}
                      display="inline-flex"
                      color="#F7F7F7"
                      radius={999}
                      right={5}
                      align="center"
                      justify="center"
                      shadow="small"
                    >
                      <Icon color="#CCCCCC" size={12} icon="check" />
                    </Box>
                  );
                case "warning":
                  return (
                    <Box
                      width={18}
                      height={18}
                      display="inline-flex"
                      color="#F7F7F7"
                      radius={999}
                      right={5}
                      align="center"
                      justify="center"
                      shadow="small"
                    >
                      <Icon color="#CCCCCC" size={12} icon="alert-triangle" />
                    </Box>
                  );
                  break;
                default:
                  return null;
              }
            })()}
            <Rule display="block" size={14} weight={600} color="#505050">
              {title}
            </Rule>
          </Box>
        ) : undefined}
        <Rule size={12} weight={500} color="#909090" bottom={5} display="block">
          {description}
        </Rule>
        {buttons.length > 0 ? (
          <Box display="flex" left={-5} right={-5}>
            {buttons.map((button) => (
              <Box left={5} right={5} flex={1}>
                <Button
                  width="100%"
                  style={{ width: "100%" }}
                  theme={button.theme || "primary"}
                  press={button.press}
                >
                  {button.text}
                </Button>
              </Box>
            ))}
          </Box>
        ) : undefined}
      </Box>
    </>
  );
};

export default Component;
