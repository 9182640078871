import React from "react";
import { connect } from "react-redux";

import { Box } from "../Bitter";
import { Functions } from "../Helpers";
import { API } from "../Modules";

import { Screen, Glass, LicensePopup } from "../Bitter";
import Toast from "react-hot-toast";

class Page extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: false, auth: false };
  }

  componentDidMount() {
    const local = localStorage.getItem("license");
    if (local) {
      this.props.core("admin", local);
      this.setState({ auth: true });
    }
  }

  render() {
    const { loading, auth } = this.state;
    const { children } = this.props;
    if (auth) {
      return children;
    }
    return (
      <Box
        color="#FFFFFF"
        width="100%"
        height="100%"
        display="flex"
        align="center"
        justify="center"
        direction="column"
      >
        <Screen screen="home" crumbs={["Home"]} />
        <Glass active={true}>
          <LicensePopup
            active={true}
            title="License"
            description="Enter your license key to access to the dashboard."
            done={async (license) => {
              const correct_license = await API.post("/dashboard/auth", {
                license: license,
              });
              if (correct_license.correct_license) {
                localStorage.setItem("license", license);
                this.props.core("admin", license);
                this.setState({ auth: true });
              } else {
                Toast.error(correct_license.message);
              }
            }}
          />
        </Glass>
      </Box>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Page);
