import React, { useState, useEffect } from "react";
import { Box, Rule, Icon, Mobile } from "../../Bitter";
import { Colors, Functions, Resources } from "../../Helpers";

const Component = ({ username, press }) => {
  return (
    <>
      <Box display="flex" align="center" press={press}>
        <Rule color={Colors.primary} rule="Small" right={10}>
          {username}
        </Rule>
        <Icon icon="log-out" color={Colors.primary} size={12} />
      </Box>
    </>
  );
};

export default Component;
