import React, { useState, useEffect } from "react";
import { Box, Rule, Icon, Mobile, Button, Glass, Details } from "../../Bitter";
import { Colors, Functions, Resources } from "../../Helpers";
import { API } from "../../Modules";

const Component = ({ title, entities = [], bottom = 0, quick = false }) => {
  const [open, set] = useState(false);
  const [popup, _popup] = useState(false);
  const [details, _details] = useState({});

  return (
    <>
      {details ? (
        <Glass active={popup}>
          <Box css="--popup" outer={() => _popup(false)}>
            {(() => {
              let temporary = [];

              Object.entries(details).forEach(([key, value]) => {
                temporary.push([key, value]);
              });

              return (
                <Details
                  size={512}
                  padding={20}
                  title={details._id}
                  details={temporary}
                />
              );
            })()}
          </Box>
        </Glass>
      ) : undefined}
      <Box bottom={bottom}>
        <Rule
          weight={600}
          size={13}
          display="block"
          color={Colors.black}
          bottom={10}
        >
          {title}
        </Rule>
        {entities.map((entity, e) => {
          if (!open && e >= 3) {
            return null;
          }
          return (
            <Box bottom={10} mode="padding" display="flex" align="center">
              <Rule
                to={entity[1] || false}
                target="_blank"
                size={12}
                color="#505050"
                display="block"
                press={async () => {
                  if (quick) {
                    let temporary = await API.post("/dashboard/quick", {
                      quick,
                      entity: entity[0],
                    });
                    if (temporary) {
                      _details(temporary);
                      _popup(true);
                    }
                  }
                }}
              >
                {entity[0]}
              </Rule>
              <Icon icon="external-link" color="#CCCCCC" size={12} left={4} />
            </Box>
          );
        })}
        {entities.length > 3 ? (
          <Button theme="tetriary" size="small" press={() => set(!open)}>
            {open ? "Less" : "More"}
          </Button>
        ) : undefined}
      </Box>
    </>
  );
};

export default Component;
