import React, { useState, useEffect } from "react";
import { Box, Rule, Icon, Mobile } from "../../Bitter";
import { Colors, Functions, Resources } from "../../Helpers";

import BarLoader from "react-spinners/BarLoader";

const SIZE = {
  medium: 32,
  small: 24,
};
const PADDING = {
  medium: 10,
  small: 5,
};
const TEXT = {
  medium: 13,
  small: 10,
};

const BORDER = {
  primary: "none",
  disabled: "none",
  secondary: "2px solid #F00000",
  tetriary: "1px solid #DDDDDD",
};
const COLOR = {
  primary: "#FFFFFF",
  disabled: "#CCCCCC",
  secondary: "#F00000",
  tetriary: "#CCCCCC",
};
const BACKGROUND = {
  primary: "#F00000",
  disabled: "#DDDDDD",
  secondary: "transparent",
  tetriary: "transparent",
};

const LOADING_SOUND = new Audio("/assets/sound/loading.mp3");
LOADING_SOUND.loop = true;
LOADING_SOUND.volume = 0.25;

const Component = ({
  children,
  press = () => {},
  theme = "primary",
  size = "medium",
  style,
  left,
  right,
  top,
  bottom,
  loading = false,
  silent = false,
}) => {
  useEffect(() => {
    if (loading) {
      if (!silent) {
        LOADING_SOUND.play();
      }
    }
    if (!loading) {
      if (!silent) {
        LOADING_SOUND.pause();
      }
    }
  }, [loading]);

  return (
    <>
      <Box
        css="--button"
        display="inline-flex"
        align="center"
        justify="center"
        radius={4}
        color={BACKGROUND[theme]}
        height={SIZE[size]}
        border={BORDER[theme]}
        left={left}
        right={right}
        top={top}
        bottom={bottom}
        style={{
          paddingLeft: PADDING[size],
          paddingRight: PADDING[size],
          ...style,
        }}
        press={async () => {
          if (loading) {
            return false;
          }

          if (press) {
            press();
          }

          if (!silent) {
            const CLICK_SOUND = new Audio("/assets/sound/click.mp3");
            CLICK_SOUND.volume = 0.2;
            CLICK_SOUND.play();
          }
        }}
      >
        {loading ? (
          <BarLoader
            height={6}
            width={typeof children === "string" ? children.length * 6 : 64}
            color={COLOR[theme]}
            loading={true}
          />
        ) : typeof children === "string" ? (
          <Rule rule="Button" size={TEXT[size]} color={COLOR[theme]}>
            {children}
          </Rule>
        ) : (
          children
        )}
      </Box>
    </>
  );
};

export default Component;
