import React from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import {
  HomeScreen,
  UsersScreen,
  UsersEditScreen,
  ProjectsScreen,
  ProjectsEditScreen,
  DonationsScreen,
  DonationsEditScreen,
  AuthScreen,
} from "../Screens";

class Component extends React.PureComponent {
  render() {
    const { redux = {} } = this.props.redux;

    return (
      <AuthScreen>
        <Router>
          <Switch>
            <Route exact path="/users/:entity" component={UsersEditScreen} />
            <Route exact path="/users" component={UsersScreen} />
            <Route
              exact
              path="/projects/:entity"
              component={ProjectsEditScreen}
            />
            <Route exact path="/projects" component={ProjectsScreen} />
            <Route
              exact
              path="/donations/:entity"
              component={DonationsEditScreen}
            />
            <Route exact path="/donations" component={DonationsScreen} />
            <Route exact path="/:any" component={HomeScreen} />
            <Route exact path="/" component={HomeScreen} />
          </Switch>
        </Router>
      </AuthScreen>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Component);
