import Axios from "axios";
import Store from "./Store";
import Toast from "react-hot-toast";
import { Resources } from "../Helpers";

class API {
  static async post(slug, request = {}, announce = false) {
    const admin = Store.getState().admin;

    const promise = new Promise((resolve) => {
      request.admin = admin;
      Axios.post(Resources.url + slug, request, {
        headers: {},
      })
        .then((res) => {
          const data = res.data;

          resolve(data);
        })
        .catch(() => {
          if (announce) {
            Toast((t) => "Bir sorun oluştu. Lütfen daha sonra deneyiniz.");
          }

          resolve(false);
        });
    });
    const result = await promise;
    return result;
  }

  static async silent(slug, request = {}) {
    return await this.request(slug, request, false);
  }

  static source(uuid) {
    return Resources.url + "/picture/" + uuid + ".jpg";
  }
}

export default API;
