import React, { useState, useEffect } from "react";
import { Box, Rule, Icon, Mobile } from "../../Bitter";
import { Colors, Functions, Resources } from "../../Helpers";

const Component = ({ name, username, to, action }) => {
  return (
    <>
      <Box
        mode="padding"
        display="inline-flex"
        width="auto"
        all={10}
        border="1px solid #DDDDDD"
        radius={8}
        direction="column"
      >
        <Rule rule="Body" display="block" bottom={0}>
          {name}
        </Rule>
        <Rule rule="Small" display="block" bottom={10}>
          {username}
        </Rule>
        <Box display="inline-flex" align="center">
          <Icon
            icon="external-link"
            color={Colors.primary}
            size={12}
            right={4}
          />
          <Rule
            to={to}
            rule="Tiny"
            target="_blank"
            display="block"
            line={12}
            position="relative"
            bottom={-4}
            color={Colors.primary}
          >
            {action}
          </Rule>
        </Box>
      </Box>
    </>
  );
};

export default Component;
