import React from "react";
import { connect } from "react-redux";

import { Box, Rule, Text } from "../Bitter";
import { Functions, Colors, Resources } from "../Helpers";
import { API } from "../Modules";

import Lodash from "lodash";

import {
  Screen,
  SearchInput,
  FilterPopup,
  IconButton,
  Glass,
  Popup,
} from "../Bitter";

import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";

const DEFAULT_FILTER = {
  temporary: false,
};

class Page extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      search: "",
      _filter: false,
      filter: {},
      entities: [],
      trash: false,
    };
  }

  async componentDidMount() {
    this._reload();
  }

  _reload = async () => {
    this.setState({ loading: true });
    const entities = await API.post("/dashboard/users");
    if (entities) {
      this.setState({
        entities: entities.entities,
        loading: false,
        trash: false,
      });
    }
  };

  _delete = async (trash) => {
    const response = await API.post("/dashboard/users", { delete: trash });
    if (response) {
      this._reload();
    }
  };

  render() {
    const { loading, search, filter, _filter, entities, trash } = this.state;

    const rows =
      search.length > 0
        ? Lodash.filter(entities, (entity) =>
            JSON.stringify(entity).includes(search)
          )
        : Functions.filter(entities, { ...DEFAULT_FILTER, ...filter });

    return (
      <Box
        color="#FFFFFF"
        width="100%"
        height="100%"
        display="flex"
        align="center"
        justify="center"
        direction="column"
      >
        <Screen
          screen="users"
          crumbs={["Home", "Users"]}
          sticky={
            <Box display="flex" align="center" justify="space-between">
              <Rule rule="Title">Users</Rule>
              <Box display="flex" align="center">
                <Box position="relative">
                  <IconButton
                    right={10}
                    icon="sliders"
                    press={() => this.setState({ _filter: !_filter })}
                  />
                  <Box
                    position="absolute"
                    mode="position"
                    top="calc(100% + 10px)"
                    right="0"
                    left="unset"
                    bottom="unset"
                    layer={99}
                  >
                    <FilterPopup
                      display={_filter}
                      change={(filter) => this.setState({ filter })}
                      outer={() => this.setState({ _filter: false })}
                      filters={[
                        { type: "string", key: "name", label: "Name" },
                        { type: "string", key: "surname", label: "Surname" },
                        { type: "string", key: "email", label: "Email" },
                        {
                          type: "boolean",
                          key: "temporary",
                          label: "Temporary",
                        },
                        { type: "boolean", key: "premium", label: "Premium" },
                      ]}
                    />
                  </Box>
                </Box>

                <SearchInput
                  value={search}
                  change={(search) => this.setState({ search })}
                />
              </Box>
            </Box>
          }
        >
          {!loading ? (
            <DataGrid
              rows={rows}
              getRowId={(row) => row._id}
              columns={[
                ...Functions.columns({
                  id: "#",
                  email: "Email",
                  name: "Name",
                  surname: "Surname",
                  username: "Username",
                }),
                {
                  field: "actions",
                  type: "actions",
                  width: 48,
                  flex: 1,
                  getActions: (params) => [
                    <GridActionsCellItem
                      onClick={() =>
                        (window.location = "/users/" + params.row._id)
                      }
                      icon={<EditIcon />}
                      label="Edit"
                    />,
                    <GridActionsCellItem
                      onClick={() => this.setState({ trash: params.row._id })}
                      icon={<DeleteIcon />}
                      label="Delete"
                    />,
                  ],
                },
              ]}
              initialState={{
                pinnedColumns: { left: ["id"], right: ["actions"] },
              }}
              checkboxSelection
              disableSelectionOnClick
            />
          ) : undefined}
        </Screen>
        <Glass active={trash}>
          <Popup
            active={trash}
            icon="Error"
            title="Are you sure?"
            description={
              "You are going to be deleting the record with the id: " + trash
            }
            buttons={[
              {
                theme: "primary",
                press: () => this.setState({ trash: false }),
                text: "No",
              },
              {
                theme: "tetriary",
                press: () => this._delete(trash),
                text: "Yes",
              },
            ]}
          />
        </Glass>
      </Box>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Page);
