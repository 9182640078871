import React from "react";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import Helmet from "react-helmet";
import { Store, Navigator, API } from "./Modules";

const App = () => {
  return (
    <Provider store={Store}>
      <Helmet>
        <title>Theatredor Crowdfunding Dashboard</title>
      </Helmet>
      <Navigator />
      <Toaster position="bottom-center" />
    </Provider>
  );
};

export default App;
