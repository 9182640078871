import React, { useState, useEffect } from "react";
import { Box, Rule, Icon, Mobile } from "../../Bitter";
import { Colors, Functions, Resources } from "../../Helpers";

const Component = ({
  title,
  details = [],
  padding = 10,
  size = undefined,
  width,
  style,
  expand = false,
}) => {
  const [expanded, set] = useState(false);
  return (
    <>
      <Box
        radius={8}
        color="#F7F7F7"
        mode="padding"
        left={padding}
        top={padding}
        right={padding}
        style={{
          maxWidth: size || width,
          maxHeight: size,
          overflowY: size ? "auto" : undefined,
          ...style,
        }}
      >
        <Box
          display="flex"
          align="center"
          justify="space-between"
          bottom={expand !== false ? 0 : 10}
          press={() => set(!expanded)}
          style={{ paddingBottom: expand !== false ? 10 : 0 }}
        >
          <Rule weight={600} size={13} display="block" color={Colors.black}>
            {title}
          </Rule>
          {expand !== false ? (
            <Icon
              opacity={0.5}
              size={13}
              icon={expanded ? "chevron-up" : "chevron-down"}
            />
          ) : undefined}
        </Box>
        <Box
          style={
            expand !== false
              ? { display: expanded ? "block" : "none" }
              : undefined
          }
        >
          {details.map((detail) => (
            <Box bottom={padding} mode="padding">
              <Rule size={11} color="#909090" display="block">
                {detail[0]}
              </Rule>
              {typeof detail[1] === "object" ? (
                <Box
                  top={10}
                  style={{
                    padding: 10,
                    backgroundColor: "#1E1E1E",
                    overflowX: "auto",
                  }}
                  radius={10}
                >
                  <Rule size={13} color="#909090" display="block">
                    {JSON.stringify(detail[1])}
                  </Rule>
                </Box>
              ) : typeof detail[1] === "boolean" ? (
                <Box
                  display="inline-flex"
                  align="center"
                  justify="center"
                  top={5}
                  style={{
                    padding: 5,
                    backgroundColor: detail[1] ? "#52b69a" : "#e56b6f",
                  }}
                  radius={4}
                >
                  <Rule
                    size={10}
                    color="#FFFFFF"
                    display="inline-block"
                    weight="600"
                  >
                    {detail[1] ? "True" : "False"}
                  </Rule>
                </Box>
              ) : (
                <Rule size={13} color="#505050" display="block">
                  {detail[1]}
                </Rule>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default Component;
