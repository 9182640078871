import React, { useState, useEffect } from "react";
import { Box, Rule, Icon, Mobile } from "../../Bitter";
import { Colors, Functions, Resources } from "../../Helpers";

const Component = ({
  value,
  change,
  bottom,
  left,
  right,
  top,
  style,
  placeholder = false,
  label = false,
}) => {
  return (
    <Box bottom={bottom} left={left} right={right} top={top} style={style}>
      {label ? <Rule rule="Label">{label}</Rule> : undefined}
      <Box
        radius={8}
        border="1px solid #DDDDDD"
        mode="padding"
        top={4}
        right={4}
        bottom={4}
        left={10}
        display="flex"
        height={40}
        align="center"
      >
        <input
          placeholder={placeholder}
          value={value}
          onChange={(event) => {
            if (change) {
              change(event.target.value);
            }
          }}
          style={{ flex: 1 }}
        />
      </Box>
    </Box>
  );
};

export default Component;
