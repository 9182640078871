import React, { useState, useEffect } from "react";
import {
  Box,
  Rule,
  Icon,
  Mobile,
  Button,
  SmallInput,
  SmallCheckboxInput,
} from "../../Bitter";
import { Colors, Functions, Resources } from "../../Helpers";
import Lodash from "lodash";

import { OuterClick } from "react-outer-click";

const Component = ({
  width = 216,
  filters = [],
  change,
  outer,
  display = true,
}) => {
  const [value, set] = useState({});

  if (!display) {
    return null;
  }

  return (
    <OuterClick onOuterClick={outer}>
      <Box
        mode="padding"
        width={width}
        left={10}
        right={10}
        bottom={15}
        top={15}
        color={Colors.white}
        shadow="small"
        radius={8}
      >
        {filters.map((filter) => (
          <Box bottom={10}>
            {(() => {
              switch (filter.type) {
                case "string":
                  return (
                    <SmallInput
                      label={filter.label}
                      value={value[filter.key]}
                      change={(change) => {
                        let temporary = Lodash.clone(value);
                        temporary[filter.key] = change;
                        set(temporary);
                      }}
                    />
                  );
                  break;
                case "boolean":
                  return (
                    <SmallCheckboxInput
                      label={filter.label}
                      active={value[filter.key]}
                      change={(change) => {
                        let temporary = Lodash.clone(value);
                        temporary[filter.key] = change;
                        set(temporary);
                      }}
                    />
                  );
                  break;
                default:
                  return null;
              }
            })()}
          </Box>
        ))}
        <Box display="flex" align="center">
          <Button
            size="small"
            theme="primary"
            press={() => {
              if (change) {
                change(value);
              }
            }}
          >
            Apply
          </Button>
          <Button
            left={10}
            size="small"
            theme="tetriary"
            press={() => {
              set({});
              if (change) {
                change({});
              }
              if(outer){
                outer();
              }
            }}
          >
            Clear
          </Button>
        </Box>
      </Box>
    </OuterClick>
  );
};

export default Component;
