import React, { useState, useEffect } from "react";
import { Box, Rule, Icon, Mobile } from "../../Bitter";
import { Colors, Functions, Resources } from "../../Helpers";

const Component = ({ children, buttons }) => {
  const [active, set] = useState(false);

  return (
    <>
      <Box position="relative">
        <Box press={() => set(!active)}>{children}</Box>
        {active ? (
          <Box
            position="absolute"
            mode="position"
            top="calc(100% + 10px)"
            right={0}
            left="unset"
            bottom="unset"
            style={{ padding: 20 }}
            layer={3}
            radius={10}
            shadow="medium"
            color={Colors.white}
            width={216}
          >
            {buttons.map((button, b) => (
              <Box
                bottom={b !== buttons.length - 1 ? 10 : 0}
                press={button.press}
                display="flex"
                align="center"
              >
                {button.icon ? (
                  <Icon icon={button.icon} size={16} right={10} color="#909090" />
                ) : undefined}
                <Rule rule="Small" color="#505050">
                  {button.text}
                </Rule>
              </Box>
            ))}
          </Box>
        ) : undefined}
      </Box>
    </>
  );
};

export default Component;
