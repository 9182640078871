import React from "react";
import FeatherIcon from "feather-icons-react";
import { Box } from "../../Bitter";
import { Colors } from "../../Helpers";
import Lodash from "lodash";

const Component = (props) => {
  const {
    icon,
    size = 24,
    press,
    color,
    weight,
    style,
    fill,
    stroke = 3,
  } = props;

  let properties = props;
  if (properties.color) {
    properties = Lodash.omit(properties, "color");
  }

  return (
    <Box height={size} width={size} press={press} display="inline-flex" align="center" justify="center" {...properties}>
      <FeatherIcon
        strokeWidth={stroke}
        size={size}
        icon={icon}
        weight={weight}
        stroke={color ? color : Colors.black}
        style={{ fill, ...style }}
      />
    </Box>
  );
};

export default Component;
