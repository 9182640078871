import React, { useState, useEffect } from "react";
import { Box, Rule, Icon, Mobile } from "../../Bitter";
import { Colors, Functions, Resources } from "../../Helpers";

const Component = ({ children, active = false }) => {
  if (!active) {
    return null;
  }
  return (
    <>
      <Box
        position="fixed"
        layer={10}
        color="rgba(16, 17, 21, 0.85)"
        mode="position"
        css="--blur"
        display="flex"
        style={{ padding: 25 }}
        direction="column"
        align="center"
        justify="flex-end"
        width="100%"
        height="100%"
      >
        {children}
      </Box>
    </>
  );
};

export default Component;
