import React, { useState, useEffect } from "react";
import { Box, Rule, Icon, Mobile, Menu } from "../../Bitter";
import { Colors, Functions, Resources } from "../../Helpers";

const Component = ({
  active = "default",
  menu = [],
  width = 256,
  height = "100%",
}) => {
  return (
    <>
      <Box
        color="#F7F7F7"
        mode="padding"
        all={10}
        radius={8}
        width={width}
        height={height}
      >
        <img
          src="https://theatredor-wordpress-production.s3.eu-west-2.amazonaws.com/wp-content/uploads/2022/08/04105739/LogoMain.svg"
          style={{ marginBottom: 20, margin: 13, display: "block", height: 24, width: "auto" }}
        />
        {menu.map((item) => (
          <Menu
            active={item.key === active}
            label={item.label}
            icon={item.icon}
            to={item.to}
          />
        ))}
      </Box>
    </>
  );
};

export default Component;
