import React from "react";

const Component = ({
  size,
  family = "Lufga",
  lines = false,
  press,
  style,
  children,
  right,
  left,
  top,
  bottom,
  all = false,
  mode = "margin",
  display = "inline",
  opacity,
  color = "#000000",
  line = false,
  align,
  weight = "400",
  to,
  target,
}) => {
  return to ? (
    <a
      href={to}
      target={target}
      onClick={press}
      style={{
        display,
        opacity,
        color,
        textAlign: align,
        fontSize: size,
        fontFamily: family,
        fontWeight: weight,
        lineHeight: line ? line + "px" : undefined,
        cursor: press ? "pointer" : undefined,
        paddingTop: mode === "padding" ? (all ? all : top) : undefined,
        paddingLeft: mode === "padding" ? (all ? all : left) : undefined,
        paddingBottom: mode === "padding" ? (all ? all : bottom) : undefined,
        paddingRight: mode === "padding" ? (all ? all : right) : undefined,
        marginTop: mode === "margin" ? (all ? all : top) : undefined,
        marginLeft: mode === "margin" ? (all ? all : left) : undefined,
        marginBottom: mode === "margin" ? (all ? all : bottom) : undefined,
        marginRight: mode === "margin" ? (all ? all : right) : undefined,
        top: mode === "position" ? (all ? all : top) : undefined,
        left: mode === "position" ? (all ? all : left) : undefined,
        bottom: mode === "position" ? (all ? all : bottom) : undefined,
        right: mode === "position" ? (all ? all : right) : undefined,
        ...style,
      }}
    >
      {children}
    </a>
  ) : (
    <span
      onClick={press}
      style={{
        display,
        opacity,
        color,
        textAlign: align,
        fontSize: size,
        fontFamily: family,
        fontWeight: weight,
        lineHeight: line ? line + "px" : undefined,
        cursor: press ? "pointer" : undefined,
        paddingTop: mode === "padding" ? (all ? all : top) : undefined,
        paddingLeft: mode === "padding" ? (all ? all : left) : undefined,
        paddingBottom: mode === "padding" ? (all ? all : bottom) : undefined,
        paddingRight: mode === "padding" ? (all ? all : right) : undefined,
        marginTop: mode === "margin" ? (all ? all : top) : undefined,
        marginLeft: mode === "margin" ? (all ? all : left) : undefined,
        marginBottom: mode === "margin" ? (all ? all : bottom) : undefined,
        marginRight: mode === "margin" ? (all ? all : right) : undefined,
        top: mode === "position" ? (all ? all : top) : undefined,
        left: mode === "position" ? (all ? all : left) : undefined,
        bottom: mode === "position" ? (all ? all : bottom) : undefined,
        right: mode === "position" ? (all ? all : right) : undefined,
        ...style,
      }}
    >
      {children}
    </span>
  );
};

export default Component;
