import React, { useState, useEffect } from "react";
import { Box, Rule, Icon, Mobile } from "../../Bitter";
import { Colors, Functions, Resources } from "../../Helpers";

const Component = ({ active = false, change = () => {} }) => {
  const [value, set] = useState(active);
  useEffect(() => {
    set(active);
  }, [active]);

  return (
    <>
      <Box
        width={13}
        height={13}
        color={value ? Colors.primary : "#F7F7F7"}
        border={value ? undefined : "1px solid #DDDDDD"}
        display="inline-flex"
        align="center"
        justify="center"
        radius={4}
        press={() => {
          let _value = !value;

          if (change) {
            change(_value);
          }

          set(_value);
        }}
      >
        {value ? <Icon icon="check" size={10} color={Colors.white} /> : undefined}
      </Box>
    </>
  );
};

export default Component;
